@if(directCall();as call){
<rcg-card>
  <div style="display: flex; align-items: center" title>
    @if (callService.isInboundCall(call)) {
    <mat-icon
      [matTooltip]="'incoming_call' | intl"
      class="phone-call"
      [class.success]="callService.isSuccessfulCall(call)"
      [class.failure]="!callService.isSuccessfulCall(call)"
    >
      phone_callback
    </mat-icon>
    } @else if (callService.isOutboundCall(call)) {
    <mat-icon
      [matTooltip]="'outgoing_call' | intl"
      class="phone-call"
      [class.success]="callService.isSuccessfulCall(call)"
      [class.failure]="!callService.isSuccessfulCall(call)"
    >
      phone_forwarded
    </mat-icon>
    } @else {
    <mat-icon [matTooltip]="'unknown_direction_call' | intl" class="phone-call"> phone </mat-icon>
    }

    <span class="call-type">
      @if (callService.isInboundCall(call)) {
      {{ 'incoming_call' | intl }}
      } @else if (callService.isOutboundCall(call)) {
      {{ 'outgoing_call' | intl }}
      } @else {
      {{ 'unknown_call' | intl }}
      }
    </span>
  </div>

  <div titleAction>
    <div style="display: flex; justify-content: space-between; align-items: center">
      @if (callService.isSuccessfulCall(call)) { @if (call.start_date) { @if (call.start_date | isToday) {
      {{ call.start_date | date : 'shortTime' }}
      } @else {
      {{ call.start_date | date : 'd. MM. yy, HH:mm' }}
      } } } @else { @if (call.failure_date) { @if (call.failure_date | isToday) {
      {{ call.failure_date | date : 'shortTime' }}
      } @else {
      {{ call.failure_date | date : 'd. MM. yy, HH:mm' }}
      } } }
    </div>
  </div>

  <div class="big-font" subtitle>{{ call.agent_name ?? call.user_display_name }}</div>

  <div subtitleAction>
    @if (callService.isInboundCall(call)) {
    {{ call.callee_number ?? '' }}
    } @else {
    {{ call.caller_number ?? '' }}
    }
  </div>

  <div content>
    <!-- '\u00A0' -->
    <div class="big-font">{{ call.contact_name ?? '-' }}</div>
    <div>{{ call.duration | callDuration }}</div>
  </div>

  <div contentAction>
    @if (callService.isInboundCall(call)) {
    {{ call.caller_number ?? '' }}
    } @else {
    {{ call.callee_number ?? '' }}
    } @if (callService.isInboundMissedCall(call)) {
    <div class="row-end">
      <div style="display: flex; align-items: center; gap: 20px">
        <span [matBadge]="call.no_of_missed_calls" matBadgeOverlap="false" matBadgeSize="small"></span>
        <mat-icon class="small-icon" [matTooltip]="'missed_incoming_call' | intl" color="accent">call_missed</mat-icon>
      </div>
    </div>
    } @else if (callService.isOutboundMissedCall(call)) {
    <div class="row-end">
      <div style="display: flex; align-items: center; gap: 20px">
        <span [matBadge]="call.no_of_missed_calls" matBadgeOverlap="false" matBadgeSize="small"></span>
        <mat-icon class="small-icon" [matTooltip]="'missed_outgoing_call' | intl" color="accent">call_missed_outgoing</mat-icon>
      </div>
    </div>
    }
  </div>

  <!-- <ng-container *ngIf="phoneCall?.status === 'Missed'">
    <div class="row-end">
      <span [matBadge]="phoneCall.no_of_missed_calls | absoluteNumber" matBadgeOverlap="false" matBadgeSize="small"></span>
    </div>
  </ng-container> -->
</rcg-card>
}

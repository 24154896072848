@if(data){
<div class="activity-card">
  <div class="card-header">
    <h2>{{ data.short_description || '' }}</h2>
    <button mat-icon-button class="close-button" (click)="closeDetail.emit()">
      <mat-icon class="full-screen-icon">close</mat-icon>
    </button>
  </div>
  <div class="card-content">
    <div class="info-item">
      <mat-icon>event</mat-icon>
      <span
        >{{ data.id ?? '' }} (<b>{{ data.status?.description ?? '' }}</b
        >)</span
      >
    </div>
    <div class="info-item">
      <mat-icon>business</mat-icon>
      <span>{{ data.customer?.short_name ?? '' }}</span>
    </div>
    <div class="info-item">
      <mat-icon>person</mat-icon>
      <span>{{ data.contact?.full_name ?? '' }}</span>
    </div>
    <div class="info-item">
      <mat-icon>real_estate_agent</mat-icon>
      <span>{{ data.user?.full_name ?? '' }}</span>
    </div>
    <div class="info-item">
      <mat-icon>update</mat-icon>
      <span>
        {{ data.rruleset?.dtstart | date : 'dd.MMM.yyyy HH:mm' }}
        -
        {{
          data.rruleset?.dtend
            | date
              : ((data.rruleset?.dtstart | date : 'dd.MM.yyyy') === (data.rruleset?.dtend | date : 'dd.MM.yyyy')
                  ? 'HH:mm'
                  : 'dd.MMM.yyyy HH:mm')
        }}
      </span>
    </div>
    <div class="info-item" style="align-items: flex-start">
      <mat-icon>summarize</mat-icon>
      <span [style.white-space]="'pre-wrap'">{{ data.report ?? '' }}</span>
    </div>
  </div>
</div>
}

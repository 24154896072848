import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IAction } from '@rcg/actions';

interface CalendarEvent {
  id?: number;
  form_id?: number;
  created_at?: string;
  updated_at?: string;
  short_description?: string;
  status?: {
    id?: number;
    description?: string;
    color?: string;
  };
  user?: {
    id?: number;
    full_name?: string;
  };
  customer?: {
    id?: number;
    short_name?: string;
  };
  contact?: {
    id?: number;
    full_name?: string;
  };
  rruleset?: {
    dtstart?: string;
    dtend?: string;
  };
  report?: string;
}

@Component({
  selector: 'rcg-sales-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatIconModule, MatCardModule, MatListModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesActivityComponent {
  @Input() data: CalendarEvent | undefined;

  @Input() actions: IAction[] = [];

  @Output() closeDetail = new EventEmitter<void>();
}

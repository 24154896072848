import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IntlModule } from '@rcg/intl';
import { RcgCardComponent } from '../../card/card.component';
import { DirectCall } from '../models/direct-call';
import { CallDuration, IsTodayPipe } from '../pipes';
import { DirectCallService } from '../services/direct-call.service';

@Component({
  selector: 'rcg-direct-call-list-item',
  standalone: true,
  templateUrl: './direct-call-list-item.component.html',
  styleUrls: ['./direct-call-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RcgCardComponent, MatIconModule, MatTooltipModule, IntlModule, IsTodayPipe, CallDuration, MatBadgeModule],
})
export class DirectCallListItemComponent {
  directCall = input.required<DirectCall>();

  public callService = inject(DirectCallService);
}

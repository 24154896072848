import { gql } from 'apollo-angular';

export const salesActivityByPk = gql`
  query salesActivityByPk($id: bigint!, $locale: RcgUserLocale) {
    data: sales_activities_by_pk(id: $id) {
      id
      form_id
      data
      opportunity_id
      tenant_id
      created_at
      updated_at
      short_description
      report
      dt_start
      dt_end
      status {
        id
        description: description_ml(path: $locale)
        color
      }
      user {
        id
        full_name
      }
      customer {
        id
        short_name
      }
      contact {
        id
        full_name
      }
    }
  }
`;
